import React, { useEffect, useState } from 'react';
import { BsFillStarFill } from '@react-icons/all-files/bs/BsFillStarFill';
import { useInView } from 'react-intersection-observer';
import SectionTitle from '../../../section-title';
import * as style from './index.module.scss';

export default function Testimonials() {
  const { ref, inView } = useInView({ threshold: 0.1 });
  const [showVideos, setShowVideos] = useState(false);

  useEffect(() => {
    if (inView) {
      setShowVideos(true);
    }
  }, [inView]);

  return (
    <section
      ref={ref}
      className={
        style.testimonials +
        ' m-auto d-block d-md-flex flex-column align-items-center justify-content-center'
      }
    >
      <div className={style.wrapper + ' container d-md-flex flex-column align-items-center'}>
        <header className={'text-md-center'}>
          <SectionTitle className={style.heading + ' text-center'}>
            Let's get started on saving you money today!
          </SectionTitle>
          <p className="text-center">
            "What a great company! We dealt with Mark, who was so personable and quick to assist us
            with our renewal. We have been dealing with Nuborrow for a few years and appreciate the
            transparency and advice as we navigate through the process!"
          </p>
          <div
            className={
              style.rating + ' d-flex flex-column align-items-center justify-content-center'
            }
          >
            <span className={style.stars + ' d-flex align-items-center'}>
              <BsFillStarFill />
              <BsFillStarFill />
              <BsFillStarFill />
              <BsFillStarFill />
              <BsFillStarFill />
            </span>
            <span>M Joy - Joined Nuborrow September 2021</span>
            <span className="text-center">
              <a
                className={'text-primary'}
                target="_blank"
                href="https://www.google.com/maps/place/Nuborrow+-+Mortgage+Broker/@43.8350235,-79.5060492,15z/data=!4m7!3m6!1s0x0:0xf95300d3acb3dcec!8m2!3d43.8350755!4d-79.5057733!9m1!1b1"
              >
                Rated 4.8 on Google <br className="d-sm-none" />[ read more online reviews ]
              </a>
            </span>
          </div>
        </header>
        <div
          className={`d-flex ${style.videos} flex-column flex-wrap align-items-center align-items-lg-stretch flex-lg-row`}
        >
          <div className={style.videoWrapper}>
            {showVideos && (
              <iframe
                src="https://www.youtube-nocookie.com/embed/hqr5nk-wGiQ?controls=0"
                title="YouTube video player"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            )}
          </div>
          <div className={style.videoWrapper}>
            {showVideos && (
              <iframe
                src="https://www.youtube-nocookie.com/embed/GZR9RA9YKJo?controls=0"
                title="YouTube video player"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            )}
          </div>
          <div className={style.videoWrapper}>
            {showVideos && (
              <iframe
                src="https://www.youtube-nocookie.com/embed/aMv6e88Dskw?controls=0"
                title="YouTube video player"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            )}
          </div>
          <div className={style.videoWrapper}>
            {showVideos && (
              <iframe
                src="https://www.youtube-nocookie.com/embed/TnmBwJ959nw?controls=0"
                title="YouTube video player"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
